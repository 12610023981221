<template>
	<main class="auth-page-container">
		<div class="main-container">
			<div class="form-container" :class="{ wider: isRegistrationFormVisible }">
				<div v-if="isCommunityLogin && theme.loginContent" v-html="theme.loginContent"></div>
				<div class="logo" :style="logo"></div>
				<transition-group name="expand" class="login-registration">
					<div
						v-if="!isRegistrationFormVisible"
						key="login"
						class="login-form-container"
						:class="{ 'form-has-errors': hasErrors }"
					>
						<transition name="fade" mode="out-in">
							<ast-sign-in-form
								v-if="isSignInFormVisible && !isForgotPasswordFormVisible"
								key="signIn"
								:has-errors="hasErrors"
								:is-submitting="isSubmitting"
								:show-register="consultantId !== null"
                :select-region-icon="selectRegionIcon"
                :select-region-text="selectRegionText"
                :enable-sign-in-button="regionSelected"
                :show-region-select="showRegionSelect"
                :region-list="regionList"
								@register="openRegistrationForm"
								@submit="submitSignIn"
                @action="regionSelect"
							/>
							<ast-forgot-password-form
								v-if="isForgotPasswordFormVisible && !isSignInFormVisible"
								key="forgot"
								:has-errors="hasErrors"
								:is-submitting="isSubmitting"
								:submitted-successfully="forgotPasswordSubmittedSuccessfully"
								@submit="submitForgotPassword"
							/>
							<ast-reset-password-form
								v-if="isResetPasswordFormVisible"
								key="reset"
								:username="username"
								:enforce-password-complexity="enforcePasswordComplexity"
								:has-errors="hasErrors"
								:is-submitting="isSubmitting"
								@submit="submitResetPassword"
							/>
						</transition>
					</div>
					<div v-else key="register" class="registration-form-container">
						<a class="sign-in" @click="closeRegistrationForm">
							Already have an account? Sign in.
						</a>
						<ast-user-profile-form
							:is-submitting="isSubmittingRegistration"
							is-new-user
							@cancel="closeRegistrationForm"
							@submit="submitRegistration"
						/>
					</div>
				</transition-group>
				<ast-inline-notification
					:message="inlineNotificationMessage"
					:title="inlineNotificationTitle"
					:type="inlineNotificationType"
					:show-until="inlineNotificationShowUntil"
				/>
				<div class="omni-version">Omni Release Version: {{ release }}</div>
			</div>
			<div class="login-background" :style="{ backgroundImage }"></div>
		</div>
	</main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AstSignInForm from '@components/view/LoginSignInForm'
import AstForgotPasswordForm from '@components/view/LoginForgotPasswordForm'
import AstResetPasswordForm from '@components/view/LoginResetPasswordForm'
import AstUserProfileForm from '@components/UserProfileForm'
import AstInlineNotification from '@components/InlineNotification'
import { showAlert, showInfo, openMessageDlg } from '@dialogs/MessageDlg.vue'
import storage from '@services/storage'
import Vue from "vue";

export default {
	name: 'AuthRoute',
	components: {
		AstSignInForm,
		AstForgotPasswordForm,
		AstResetPasswordForm,
		AstUserProfileForm,
		AstInlineNotification
	},
	props: {
		// States
		isForgotPasswordFormVisible: {
			type: Boolean,
			default: false,
		},
		isResetPasswordFormVisible: {
			type: Boolean,
			default: false,
		},
		isRegistrationFormVisible: {
			type: Boolean,
			default: false,
		},
		// Community Consultant
		consultantId: {
			type: String,
			default: null,
		},
		// Set theme via url param
		themeCompany: {
			type: String,
			default: null,
		},
		// Route Params for "ResetPassword"
		username: {
			type: String,
			default: '',
		},
		token: {
			type: String,
			default: null,
		},
		enforcePasswordComplexity: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isSubmitting: false,
			isSubmittingRegistration: false,
			isSwappingForm: false,
			hasErrors: false,
			forgotPasswordSubmittedSuccessfully: false,
			release: window.config.release,
      selectRegionIcon: 'world',
      selectRegionText: 'Select your region',
      regionSelected: false,
      showRegionSelect: false,
			inlineNotificationMessage: [],
			inlineNotificationTitle: 'Maintenance Notification',
			inlineNotificationType: 'info',
			inlineNotificationShowUntil: new Date(),
			showInlineNotification: false,
		}
	},
	computed: {
		...mapState({
			theme: state => state.theme.theme,
		}),
		isCommunityLogin() {
			return this.$route.path.includes('community')
		},
		isSignInFormVisible() {
			return !this.isResetPasswordFormVisible && !this.isForgotPasswordFormVisible
		},
		backgroundImage() {
			if (this.isCommunityLogin && this.theme.loginBackgroundImage) {
				return `url(${this.theme.loginBackgroundImage})`
			} else {
				return `url(${require('@/assets/compressed/horse-dog.jpg')})`
			}
		},
		logo() {
			let backgroundImage
			let minHeight
			if (this.isCommunityLogin) {
				if (this.theme.usePoweredByLogo) {
					backgroundImage = `url(${require('@/assets/compressed/community-powered-by-logo.png')})`
				} else {
					backgroundImage = `url(${require('@/assets/compressed/community-logo.png')})`
				}
				minHeight = '83px'
			} else {
				backgroundImage = `url(${require('@/assets/compressed/asteris-omni-logo.png')})`
				minHeight = '108px'
			}
			return { backgroundImage, minHeight }
		},
		...mapGetters(['isConsultantUser', 'isRepositoryUser']),
    regionList() {
      const options =[
        {
          image: 'usa',
          label: 'North America',
          value: { action: 'select-region', data: 'region-north-america' },
        },
        {
          image: 'european-union',
          label: 'Europe',
          value: { action: 'select-region', data: 'region-europe' },
        },
        {
          image: 'asia-pacific',
          label: 'Asia/Pacific',
          value: { action: 'select-region', data: 'region-asia-pacific' },
        },
      ]
      const currentRegion = this.getCurrentRegion()
      return options.filter(option => option.value.data !== currentRegion)
    },
	},
	watch: {
		isForgotPasswordFormVisible() {
			if (!this.isForgotPasswordFormVisible) this.forgotPasswordSubmittedSuccessfully = false
		},
		themeCompany: {
			handler(company) {
				if (!company) return
				this.$store.dispatch('setTheme', { company, mode: 'light' })
			},
			immediate: true,
		},
	},
	beforeRouteEnter(to, from, next) {
		const lastLogin = storage.getItem('lastLogin')
		// For users accessing a community URL from an email link, bookmark, etc.,
		// - show login form if browser has logged in before
		// - show registration form if browser has not logged in before
		if (lastLogin && !from.name && to.name === 'community-register') {
			next({
				name: 'community-referral-login',
				params: {
					consultantId: to.params.consultantId,
				},
			})
		} else if (!lastLogin && !from.name && to.name === 'community-referral-login') {
			next({
				name: 'community-register',
				params: {
					consultantId: to.params.consultantId,
					isRegistrationFormVisible: true,
				},
			})
			// For users accessing the non-community login route who last logged in as a community user,
			// redirect to the community login form
		} else if (lastLogin === 'community' && to.name === 'login') {
			next({ name: 'community-login' })
			// Otherwise, show the Omni login form
		} else {
			next()
		}
	},
  async mounted() {
    const disableMsg = false // disable message
    if (disableMsg) return
    const currentUrl = window.location.href;
    const isUSServer = currentUrl === 'https://keystone.asteris.com/#/login'
    const today = new Date().valueOf()
    let endDate = new Date('03/13/2024')
    endDate.setUTCHours(4, 0, 0, 0) // 03/13/2024 00:00 pm EST
    if ((today <= endDate.valueOf())) {
      const message = [
        '<h3>Maintenance Notification:</h3>',
        '<p>We will be conducting routine maintenance between 8pm and midnight ET on ' +
        'Tuesday the 12th of March 2024. We do not anticipate any downtime; however, ' +
        'we wanted to notify you in case of any potential disruptions. </p>' +
        '<p>Thank you for your understanding.</p>',
      ]
      await openMessageDlg(null,{lines: message, type: 'alert'})
    }
    const isInactivityLoggedOut = storage.getItem('inactivityTimeout')
    if (isInactivityLoggedOut) {
      const message = [
        '<h3>Inactivity Logout</h3>',
        '<p>You have been logged out due to inactivity.</p>' +
        '<p>Please log in again to continue.</p>'
      ]
      storage.removeItem('inactivityTimeout')
      await openMessageDlg(null,{lines: message, type: 'alert'})
    }
    const currentRegion = this.getCurrentRegion()
    switch (currentRegion) {
      case 'region-north-america':
        this.selectRegionIcon = 'img/usa.svg'
        this.selectRegionText = 'North America'
        this.regionSelected = true
        break
      case 'region-europe':
        this.selectRegionIcon = 'img/european-union.svg'
        this.selectRegionText = 'Europe'
        this.regionSelected = true
        break
      case 'region-asia-pacific':
        this.selectRegionIcon = 'img/asia-pacific.svg'
        this.selectRegionText = 'Asia/Pacific'
        this.regionSelected = true
        break
    }
    if (window.config.environmentName.includes('Production')){
      this.showRegionSelect = true
    }
		this.inlineNotificationMessage = [
			'Please be advised that we will be performing critical maintenance beginning this evening at 8:00 PM MT. The maintenance is expected to continue until the morning.',
			'We will be accelerating our move to new hardware in an effort to prevent further disruptions to service and improve performance overall.',
			'We appreciate your patience and understanding as we work to improve our systems. We will keep downtime to a minimum and will notify you once maintenance is complete.',
			'Thank you for your cooperation.',
		]
		this.inlineNotificationShowUntil = new Date('2024-08-23T10:00:00.000-06:00') // 2024-08-23 10:00 am MT
		this.showInlineNotification = this.selectRegionText === 'North America'
  },
	methods: {
		...mapActions(['logIn', 'createCommunityUser', 'resetPassword', 'addConsultant', 'startIdleTimer']),
		openRegistrationForm() {
			this.$router.push(`/community/${this.consultantId}/register`)
		},
		closeRegistrationForm() {
			this.$router.push(`/community/${this.consultantId}`)
		},
		async submitSignIn(form) {
			if (this.isSubmitting) {
				return
			}

			if (!form.username) {
				this.hasErrors = true
				this.$notifications.addError('You must provide a username')
				return
			}

			if (!form.password) {
				this.hasErrors = true
				this.$notifications.addError('You must provide a password')
				return
			}

			this.isSubmitting = true
			this.hasErrors = false

			var params = {
				username: form.username,
				password: form.password,
				omniVersion: window.config.release,
			}

			try {
				await this.logIn({ data: params })
				if (this.$store.getters.isAuthenticated) {
          const today = new Date().valueOf()
          let startDate = new Date('2024-05-01T10:00:00.000-05:00')
          let endDate = new Date('2024-05-01T22:00:00.000-05:00')
          if ((today >= startDate.valueOf()) && (today <= endDate.valueOf())) {
            const message = [
              '<h4>Please be aware that we are currently experiencing some performance issues that may affect the following functions:</h4>',
							'<br/>' +
              '<ul>' +
              '<li>Importing</li>' +
              '<li>Telemedicine interactions</li>' +
							'<li>Archiving</li>' +
							'<li>Emailed studies</li>' +
              '</ul>',
              '<br/><strong>Our team is actively working to resolve these issues as quickly as possible. We appreciate your patience and understanding during this time.</strong><br/>'
            ]
            await openMessageDlg(null,{lines: message, type: 'info'})
          }
					if (this.$store.state.auth.claims.isConsultantGroup) {
						await showAlert(
							'You are attempting to log into Keystone as a teleconsultation group. ' +
								'This can cause problems with reports. The only reason to log into Keystone as the ' +
								'"group" is to perform administrative functions. Please log into Keystone as a ' +
								'member of the group if you wish to work on any teleconsultation reports.'
						)
					}
					if (this.consultantId)
						await this.addConsultant({
							consultantId: this.consultantId,
						})
					if (!storage.isLocalStorageSupported()) {
						// Safari private browsing
						let warning = 'Your current browser settings do not allow the sharing of data between windows. '
						if (this.$store.getters.isConsultantUser) {
							warning += 'You will not be able to use Omni across multiple windows, so some features may not work.'
						} else {
							warning += 'This may cause issues when Omni is open in multiple windows.'
						}
						await showAlert(warning)
					} else if (form.remember) {
						storage.setItem('remember-username', form.username)
					}
					this.$router.push('/')
				} else this.isSubmitting = false
			} catch (ex) {
				this.hasErrors = true
				this.isSubmitting = false
			}
		},
		async submitRegistration(form) {
			this.isSubmittingRegistration = true
			try {
				const data = { ...form, consultantId: this.consultantId }
				await this.createCommunityUser({ data })
				if (this.$store.getters.isAuthenticated) {
					this.$router.push('/')
				}
			} catch {
				this.isSubmittingRegistration = false
			}
		},
		async submitForgotPassword(form) {
			if (this.isSubmitting) {
				return
			}

			if (!form.username) {
				this.hasErrors = true
				this.$notifications.addError("Username can't be blank")
				return
			}

			this.isSubmitting = true
			this.hasErrors = false

			var params = new URLSearchParams()
			params.append('username', form.username)

			try {
				await this.$api.auth.forgotPassword(params)
				this.forgotPasswordSubmittedSuccessfully = true
			} catch (ex) {
				this.hasErrors = true
			} finally {
				this.isSubmitting = false
			}
		},
		async submitResetPassword(form) {
			if (this.isSubmitting) {
				return
			}

			if (form.password !== form.confirmPassword) {
				this.hasErrors = true
				this.$notifications.addWarning('Password and repeat password do not match.')
				return false
			}

			this.isSubmitting = true
			this.hasErrors = false

			var params = new URLSearchParams()
			params.append('username', this.username)
			params.append('password', form.password)
			params.append('token', this.token)

			try {
				await this.resetPassword(params)
				this.$router.push('/')
			} catch (ex) {
				this.hasErrors = true
			} finally {
				this.isSubmitting = false
			}
		},
    regionSelect(data) {
      Vue.nextTick(() => {
        switch (data.data) {
          case 'region-north-america':
            window.location = `https://keystone.asteris.com/`;
            break
          case 'region-europe':
            window.location = `https://keystone-eu.asteris.com/`;
            break
          case 'region-asia-pacific':
            window.location = `https://keystone.asteris.com.au/`;
            break
          default:
            this.regionSelected = false
            break
        }
      })
    },
    getCurrentRegion(){
      const isUSServer = window.config.environmentName.startsWith('US Web')
      const isEUServer = window.config.environmentName.startsWith('UK Web')
      const isAPServer = window.config.environmentName.startsWith('AUS Web')
      if (isUSServer) {
        return 'region-north-america'
      } else if (isEUServer) {
        return 'region-europe'
      } else if (isAPServer) {
        return 'region-asia-pacific'
      } else {
        return 'region-north-america'
      }
    },
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

main.auth-page-container {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.omni-version {
	color: gray;
	font-size: 12px;
}

.main-container {
	width: 100vw;
	overflow: hidden;
	display: flex;
	flex-grow: 1;

	.form-container {
		width: 38%;
		min-width: 320px;
		max-width: 450px;
		background-color: var(--primary-bg);
		display: flex;
		align-items: stretch;
		flex-direction: column;
		padding: 30px 30px 15px 30px;
		transition: width 0.2s ease, max-width 0.2s ease;
		overflow: auto;
	}

	.form-container.wider {
		width: 100%;
		max-width: 700px;
	}

	.login-registration {
		margin-bottom: auto;
		width: 100%;
	}

	.login-form-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: 230px;
		overflow: hidden;
	}

	.registration-form-container {
		width: 100%;
	}

	.login-background {
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: center;
		width: 100%;
		background-size: cover;
	}

	.logo {
		background-repeat: no-repeat;
		background-position: bottom center;
		height: 108px;
		background-size: contain;
		margin-top: auto;
	}

	a.sign-in {
		display: block;
		padding-top: 16px;
		text-align: right;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

@media (max-width: $mqSmall) {
	.main-container {
		flex-direction: column;

		.form-container {
			flex-grow: 1;
			align-items: center;
			max-width: $mqSmall;
		}

		.login-form-container,
		.form-container {
			width: 100%;
		}

		.login-splash-image {
			display: none;
		}

		.logo {
			width: 385px;
		}
	}
}

@media (max-width: $mqSmall) {
	.logo {
		width: 100% !important;
	}
}
</style>
