<template>
	<form class="forgot-password-form" @submit.prevent="$emit('submit', form)">
		<template v-if="!submittedSuccessfully">
			<div style="text-align: left;">
				<h3>Reset Your Password</h3>
				<p>
					Submit your username and we'll send you a link to reset your password.
				</p>
			</div>
			<input
				v-model="form.username"
				type="text"
				class="input"
				placeholder="Username"
				tabindex="1"
				data-cy="forgot-password-username"
			/>
			<ast-button type="primary" data-cy="forgot-password-submit">
				<template v-if="!isSubmitting">
					Submit
				</template>
				<template v-else>
					<svg-icon icon="spinner" pulse fixed />
					<span class="sr-only">Loading...</span>
				</template>
			</ast-button>
		</template>
		<template v-else>
			<p style="font-style: italic;">
				Be on the lookout for a password reset e-email if we found a matching username, an e-email
				is on its way to the user account's e-mail address.
			</p>
		</template>
		<div>
			<router-link :to="parentRoute" data-cy="back-to-sign-in">Back to Sign In</router-link>
		</div>
	</form>
</template>

<script>
import AstButton from '@components/Button'
import { consoleSandbox } from '@sentry/utils'

export default {
	name: 'ForgotPasswordForm',
	components: {
		AstButton,
	},
	props: {
		isSubmitting: {
			type: Boolean,
			required: true,
		},
		hasErrors: {
			type: Boolean,
			required: true,
		},
		submittedSuccessfully: {
			type: Boolean,
			default: false,
			required: true,
		},
	},
	data() {
		return {
			form: {
				username: '',
			},
		}
	},
	computed: {
		parentRoute() {
			return this.$route.path.replace('forgot-password', '')
		},
	},
}
</script>
<style lang="scss" scoped>
.forgot-password-form {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	margin-top: 20px;

	h3,
	p {
		text-align: left;
	}
	p {
		margin-top: 5px;
	}
	input,
	button {
		margin-top: 10px;
	}
	a {
		display: inline-block;
		margin-top: 10px;
		text-decoration: none;
		font-size: 12px;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
