<template>
	<div v-if="showMessage" :class="['message', type]">
		<div v-if="showTitle" class="title">
			<svg-icon :icon="icon" />
			<span>{{ title }}</span>
		</div>
		<p v-for="line, index in message" :key="index"> {{ line }}</p>
	</div>
</template>
<script>
import SvgIcon from '@components/SvgIcon.vue'

export default {
	name: 'InlineNotification',
	components: {
		SvgIcon
	},
	props: {
		message: {
			type: Array,
			required: true,
			default: () => []
		},
		showUntil: {
			type: Date,
			default: () => new Date()
		},
		title: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'info'
		},
		showTitle: {
			type: Boolean,
			default: true
		},
	},
	computed: {
		showMessage() {
			const now = new Date()
			return now <=	this.showUntil
		},
		icon() {
			switch (this.type) {
				case 'error':
					return 'exclamation-triangle'
				case 'info':
					return 'exclamation-circle'
				default:
					return 'exclamation-circle'
			}
		},
	}
}
</script>
<style lang="scss" scoped>
.message {
	padding: 8px;
	margin: 8px 0;

	p {
		font-size: 0.9rem;
		padding: 4px;
	}

	.title {
		display: flex;
		flex-direction: row;
		font-size: 1.2rem;
		font-weight: bold;
		padding: 4px;

		.icon {
			margin-right: 8px;
		}
	}

	&.error {
		background-color: #f8d7da;
		border: 1px solid #f5c6cb;
		color: #721c24;
	}

	&.info {
		background-color: #d1ecf1;
		border: 1px solid #bee5eb;
		color: #0c5460;
	}
}
</style>
