<template>
	<form class="sign-in-form" :class="{ 'form-has-errors': hasErrors }" @submit.prevent="$emit('submit', form)">
    <div v-if="showRegionSelect" class="login-region">
      <button-dropdown
        :active="true"
        :disabled="false"
        type="button"
      >
        <template #button >
          <img
            :src='selectRegionIcon'
            alt=""
            style="width:16px; margin-right: 8px;"
          />
          <span>{{ selectRegionText }}</span>
        </template>
        <template #dropdown="{ hide }">
          <toolbar-menu
            style="width: 100%"
            :items="regionList"
            @select="item => $emit('action', item.value) && hide()"
          />
        </template>
      </button-dropdown>
    </div>
		<div v-if="showRegister" class="register">
			<a @click="$emit('register')">Register a new account</a>
		</div>
		<ast-input
			v-model="form.username"
			v-focus="!form.remember"
			type="text"
			placeholder="User ID"
			tabindex="1"
			data-cy="username"
			:has-error="hasErrors"
		/>
		<ast-input
			v-model="form.password"
			v-focus="form.remember"
			type="password"
			placeholder="Password"
			tabindex="2"
			data-cy="password"
			:has-error="hasErrors"
		/>
		<ast-button
      :disabled="!enableSignInButton && showRegionSelect"
      type="primary"
      data-cy="sign-in">
			<template v-if="!isSubmitting">
				Sign In
			</template>
			<template v-else>
				<svg-icon icon="spinner" pulse fixed />
			</template>
		</ast-button>
		<div class="remember-or-forgot">
			<div class="remember">
				<checkbox v-model="form.remember">Remember Me</checkbox>
			</div>
			<div class="forgot-password">
				<router-link to="forgot-password" data-cy="forgot-password" append>
					Forgot Password
				</router-link>
			</div>
		</div>
	</form>
</template>

<script>
import AstInput from '@components/Input'
import AstButton from '@components/Button'
import Checkbox from '@components/Checkbox.vue'
import storage from '@services/storage'
import ButtonDropdown from '@components/ButtonDropdown.vue'
import ToolbarMenu from "@components/ToolbarMenu.vue";

export default {
	name: 'LoginSignInForm',
	components: {
    ToolbarMenu,
		AstInput,
		AstButton,
		Checkbox,
    ButtonDropdown,
	},
	props: {
		isSubmitting: {
			type: Boolean,
			required: true,
		},
		hasErrors: {
			type: Boolean,
			required: true,
		},
		showRegister: {
			type: Boolean,
			default: false,
		},
    selectRegionIcon: {
      type: String,
      default: '',
    },
    selectRegionText: {
      type: String,
      default: '',
    },
    enableSignInButton: {
      type: Boolean,
      default: true,
    },
    showRegionSelect: {
      type: Boolean,
      default: true,
    },
    regionList: {
      type: Array,
      default: () => [],
    },
	},
	data() {
		const savedUsername = storage.getItem('remember-username')
		return {
			form: {
				remember: !!savedUsername,
				username: savedUsername || null,
				password: null,
			},
		}
	},
	watch: {
		'form.remember'(shouldRemember) {
			if (!shouldRemember) storage.removeItem('remember-username')
		},
	},
  mounted() {

  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.sign-in-form {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	margin-top: 10px;
	::v-deep .input {
		margin-top: 10px;
	}
	.remember-or-forgot {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px 0;
		font-size: 14px;
	}
	button, .login-region {
		margin-top: 10px;
	}
	.forgot-password,
	.register {
		font-size: 12px;
		cursor: pointer;
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.register {
		text-align: right;
	}
}
</style>
