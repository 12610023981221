<template>
	<form class="reset-password-form" @submit.prevent="$emit('submit', form)">
		<div>
			<h3>Update your password</h3>
			<p>
				Submit your new password and we'll get you signed in.
			</p>
		</div>
		<input
			v-model="form.password"
			type="password"
			class="input"
			placeholder="Password"
			tabindex="1"
		/>
		<transition name="expand">
			<p v-if="passwordComplexityFailed" class="is-danger">
				<svg-icon icon="exclamation-circle" />
				Password does not meet requirements:
				<ul class="password-requirements">
					<li>Does not contain username</li>
					<li>Minimum of 8 characters</li>
					<li>Has at least three of the following:
						<ul>
							<li>A lowercase letter (a through z)</li>
							<li>An uppercase letter (A through Z)</li>
							<li>A numerical digit (0 through 9)</li>
							<li>A non-alphanumeric character (!, $, #, %, etc.) </li>
						</ul>
					</li>
				</ul>
			</p>
		</transition>
		<input
			v-model="form.confirmPassword"
			type="password"
			class="input"
			placeholder="Confirm Password"
			tabindex="2"
		/>
		<ast-button type="primary">
			<template v-if="!isSubmitting">
				Submit
			</template>
			<template v-else>
				<svg-icon icon="spinner" pulse fixed />
				<span class="sr-only">Loading...</span>
			</template>
		</ast-button>
	</form>
</template>

<script>
import AstButton from '@components/Button'
import { validator, RULES } from '@/utils/validation'

export default {
	name: 'ResetPasswordForm',
	components: {
		AstButton,
	},
	props: {
		isSubmitting: {
			type: Boolean,
			required: true,
		},
		hasErrors: {
			type: Boolean,
			required: true,
		},
		username: {
			type: String,
			default: null,
		},
		enforcePasswordComplexity: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			form: {
				password: '',
				confirmPassword: '',
			},
		}
	},
	computed: {
		passwordComplexityFailed() {
			if (!this.enforcePasswordComplexity) return false
			if (this.form.password.length === 0) return false
			return !validator.validateRule(this.form.password, RULES.COMPLEXITY, this.username)
		}
	}
}
</script>
<style lang="scss" scoped>
.reset-password-form {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	margin-top: 20px;

	h3,
	p {
		text-align: left;
	}
	p {
		margin-top: 5px;
	}
	input,
	button {
		margin-top: 10px;
	}
	ul.password-requirements {
		font-size: 13px;
		padding-left: 32px;
		ul {
			padding-left: 16px;
		}
	}
}
</style>
